import React, { useEffect, useRef } from 'react'
import { globalHistory } from "@reach/router"
import { graphql } from 'gatsby'
// import SEO from '../components/SEO/SEO'
// import ArticleContainer from '../containers/ArticleContainer'
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import Layout from '../containers/Layout'
import { initParticipantCategory, setInitalizedToTrue, setIsMapView, setIsCategoryView, updateRoute, setActiveParticipant, activateParticipant, setCurrentParticipants, setActivatedParticipantOffsetX} from '../state/actions'

const PageTemplate = (props) => {

  const { data, pageContext, isStateInitialized, 
    isMapView,
    initParticipantCategory, setInitalizedToTrue,
    setIsMapView, setIsCategoryView, updateRoute, setActiveParticipant, activateParticipant, participants, setCurrentParticipants, setActivatedParticipantOffsetX } = props;

  const {
    allWpParticipant: { nodes },
  } = data

  const firstParticipantId = nodes[0].databaseId;

  const ps = nodes.map(p => ({
    id: p.databaseId,
    categoryId: p.participantCategories.nodes[0].databaseId
  }))
  // console.log('nodes: ', JSON.stringify(nodes, null, 4))
  // console.log('ps: ', JSON.stringify(ps, null, 4))

  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      setIsCategoryView(true);
    }
    console.log('>>> mounted')
    if (!isStateInitialized) {
      console.log('\n\n\n>>>>> NOT isStateInitialized')
  
      initParticipantCategory(null)
      setInitalizedToTrue();
      setIsMapView(true);
      setIsCategoryView(true);
      updateRoute(globalHistory.location)
  
      setCurrentParticipants(ps)
      setActivatedParticipantOffsetX(24)
      setActiveParticipant(firstParticipantId)
      activateParticipant(firstParticipantId)
    } else if(participants.length === 0 ){
      console.log('participants.length === 0 ')
      console.log('ps: ', ps);
      setIsCategoryView(true);
      setCurrentParticipants(ps)
      setActivatedParticipantOffsetX(24)
      setActiveParticipant(firstParticipantId)
      activateParticipant(firstParticipantId)
      updateRoute(globalHistory.location)
    }
  }, []);


  if (!isStateInitialized) {
    // console.log('\n\n\n>>>>> NOT isStateInitialized')

    // initParticipantCategory(null)
    // setInitalizedToTrue();
    // setIsMapView(true);
    // setIsCategoryView(true);
    // updateRoute(globalHistory.location)

    // setCurrentParticipants(ps)
    // setActivatedParticipantOffsetX(24)
    // setActiveParticipant(firstParticipantId)
    // activateParticipant(firstParticipantId)
  } else if(participants.length === 0 ){
    // console.log('\n\n\n>>>>> YES isStateInitialized')
    // console.log('BUT participants.length is 0')
    // console.log('ps: ', ps);
    // setCurrentParticipants(ps)
    // setInitalizedToTrue();
    // setCurrentParticipants(ps)
    // setActivatedParticipantOffsetX(24)
    // setActiveParticipant(firstParticipantId)
    // activateParticipant(firstParticipantId)
  }
  return null;
  // return (
  //   <Layout>
  //     {/* <h1>all participants </h1> */}
      
  //     {/* {
  //       nodes.map(( node, count ) => {
  //         const { 
  //           latitude,
  //           longitude,
  //           streetName,
  //           streetNumber,
  //           placeId,
  //           city,
  //           postCode,
  //           streetAddress
  //         } = node.mi_participant.miParticipantGeodata;
  //         return (
  //           <div key={node.id}>
  //           </div>
  //         )
  //       })
  //     } */}
  //   </Layout>
  // )
}

export const query = graphql`
    query AllParticipants{
      allWpParticipant{
        nodes {
          databaseId
          id
          slug
          title
          uri
          participantCategories {
            nodes {
              databaseId
              name
            }
          }
          mi_participant {
            miParticipantShorttitle
            miParticipantHeroSlider {
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 540, maxWidth: 960, quality: 80, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            miParticipantGeodata {
              latitude
              longitude
              streetName
              streetNumber
              placeId
              city
              postCode
              streetAddress
            }
          }
        }
      }
    }
`

const mapStateToProps = state => {
  // console.log('mapStateToProps participantCategoryPage: ', state)
  return {
    isStateInitialized: state.isStateInitialized,
    participants: state.participants.participants,
  }
};

const mapDispatchToProps = {
  initParticipantCategory,
  setInitalizedToTrue,
  setIsMapView,
  setIsCategoryView,
  updateRoute,
  setActiveParticipant, 
  activateParticipant,
  setCurrentParticipants,
  setActivatedParticipantOffsetX
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTemplate)
